<template>
    <ValidationObserver ref="validator">
        <ValidationProvider v-slot="{errors}" :rules="`required`" name="valor">
            <label class="ml-2 my-2">Nuevo Valor</label>
            <div class="d-middle">
                <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                    {{ item.sigla }}
                </div>
                <input-miles-moneda v-model="item.valor" :id-moneda="item.id" maxlength="13" />
            </div>
            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
        </ValidationProvider>
    </ValidationObserver>
</template>
<script>
export default {
    props:{
        item:{
            type: Object,
            default: () => {
                return {sigla: '', valor: 0}
            }
        }
    }
}
</script>
